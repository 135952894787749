
.GoogleSearchBar{
    margin-bottom:5;
    width: 100%;
}

.headerRow {
	padding:13px 20px !important;
	margin: 0 !important;
	background-color:#b3faff;
}

.headerRow1 {
	padding:13px 20px !important;
	margin: 0 !important;
    background-color:#abd6a2;
    box-shadow: 0 6px 7px 0px rgba(0,0,0,0.2);
    /* border: #e9e9e9 solid 1px */
}

.buttonPrimary {
	background-color: #3d31eb !important;
    color: white !important;
}

.bold{
    font-weight: bold !important
}


.hotspot{   
    font-size: 2.2em !important;
    font-weight: bold !important;
    font-family: sans-serif !important;
    color: rgb(128, 128, 128) !important;
}

.placesListCard{
    margin: 0px 10px 10px 10px !important;
    color: #757575 !important;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2) !important;
    transition: 0.3s !important;
}

.card-shadow{
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2) !important;
}

.placesListCard .ant-card-body{
   padding: 15px 0px !important

}

.placesListCard .ant-row {
    margin: 0 !important
}

/* .placeCardLocation{
} */

.placeCardLocation span{
    color: #1f1f1f;
    padding-left: 7px
    
}
  
.placesListCard:hover {
    box-shadow: -1px 6px 12px 6px rgba(0, 0, 0, 0.384) !important;
}

.placeTableHeaders{
    font-size: 17px !important;
    margin: 0px 20px 5px 10px !important
}

.headerCard .ant-card-body{
	background-color: rgb(238, 238, 238) !important;
	padding: 0px !important;		 /*default !imp */
}

.headerCard .ant-tabs-bar {
	margin: 0 !important;
}

.headerCard{
	margin-bottom: 0 !important
}

.createPlaceCard{
    margin-bottom:0 !important;
    position:"absolute" !important;
    background-color:'#fffffff' !important;
    padding:24px !important;
    width:100% !important;
    bottom:0 !important
}

.createPlaceCard .ant-card-body{
    background-color: #ffffff !important; /*default !imp */
}

.hotspotCard{
    background-color: #ececec;
    margin: 0px;
}

.hotspotCard{
    background-color: #ececec;
    margin: 10px 15px;
    padding: 10px 0;
}

.hotspotCard .ant-checkbox-inner{
    width: 20px !important;
    height: 20px !important;

}

.addHpForm{
    height: 535px !important;           /*default !imp */
    overflow-y: scroll !important;      /*default !imp */
}


/* .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 500px;
    margin-right: 8px;
  }
  .upload-list-inline .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
  }
  .upload-list-inline .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
  }*/
  

  .upload-list-csv .ant-upload-list-item-info .anticon-paper-clip {
    font-size: 18px !important;
    padding: 0px 5px !important;
  } 
  .upload-list-csv .ant-upload-list-item-name{
    padding-left: 30px;
  }

  .upload-list-csv span{
        font-size: 20px;
        color: #4facd8;
        
  }

  .upload-list-csv .anticon-close{
    font-size: 18px !important;             /*default !imp */
  }

  .upload-list-csv .ant-upload-list-item{
      height: 30px;
  }


.Rewardx-Sidebar .ant-menu-item {
  font-size: 20px !important;
  line-height: 50px !important;
  height: auto !important;
  /* margin-bottom: 25px !important; */
}

.Rewardx-Sidebar .ant-menu {
  padding-top: 20px !important;
}

.Rewardx-Sidebar .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 5px !important;
}

.Rewardx-Sidebar .ant-menu-dark {
  background-color: #0e0e5a !important;
}

.Rewardx-Sidebar {
  /* background-color: #37474f !important; */
  background-color: #0e0e5a !important;
  overflow: hidden;
}

.Rewardx-Sidebar .ant-menu-dark .ant-menu-item-selected > a {
  /* color: #62ff77 */
  color: #fcee76;
}

.Rewardx-Topbar {
  /* background-color: #455a64 !important;
    background-image: linear-gradient(to left, #607d8b, #37474f) !important; */

  background-color: #ffffff !important;
}

.Rewardx-Logo .anticon {
  color: #5f5f5f;
}

.Rewardx-Logo .gx-layout-sider-header {
  background-color: #ffffff !important;
}

/* .overviewRowmargin {
    margin-bottom: 7px;
    margin-left: 4px;
  }

  .overviewTitlemargin {
    margin-bottom: 9px;
    margin-left: 4px;
  }*/

  .overViewBg {
    padding: 10px 15px 10px 20px;
    background-color: #e2e2e2;
  } 

  .offerBg {
    padding: 10px 15px 10px 20px;
    background-color: #ead4f1;
  }

  .audBg {
    padding: 10px 15px 10px 20px;
    background-color: #c7e4eb;
  }

  .campaignOverview .ant-row{
    margin: 0
  }

  .campaignOverview .cpName{
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 15px
  }

  .campaignOverview{
    margin-bottom: 70px
  }

  .AudienceTitle{
    padding-left: 0 !important;
    margin-bottom: 5px;
  }

  .mb-10{
    margin-bottom: 10px 
  }

  .mb-15{
    margin-bottom: 15px
  }

  .mb-25{
    margin-bottom: 25px
  }

  .mb-5{
    margin-bottom: 5px
  }

  .mb-30{
    margin-bottom: 30px
  }

  .ml-10{
    margin-left: 10px
  }

  .pl-10{
    padding-left: 10px
  }

  .divCenterVertical {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    float: left;
  }